import React from "react"
import { Link } from "gatsby"

import { MdArrowDropDown } from "react-icons/md"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CaseContainer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCaseCategory {
        edges {
          node {
            title
            id
          }
        }
      }
      allSanityCases(sort: { order: DESC, fields: publishedAt }) {
        edges {
          node {
            title
            subTitle
            _rawBody
            slug {
              current
            }
            caseCategory {
              title
              id
            }
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="caseContainer ">
      <div className="option_wrapper mobile_options animation--fade-up animation">
        <span className="arrow">
          <MdArrowDropDown />
        </span>
        <select
          onChange={optionClicked}
          id="caseCategories"
          name="caseCategories"
        >
          <option value="all">Alle</option>

          {data.allSanityCaseCategory.edges.map(function(edge) {
            return <option value={edge.node.id}>{edge.node.title}</option>
          })}
          <span>
            <MdArrowDropDown />
          </span>
        </select>
      </div>

      <div className="option_wrapper desktop_options animation--fade-up animation">
        <button onClick={tabClicked} id="all" className="tab tab_clicked">
          Alle
        </button>
        {data.allSanityCaseCategory.edges.map(function(edge) {
          return (
            <button onClick={tabClicked} id={edge.node.id} className="tab">
              {edge.node.title}
            </button>
          )
        })}
      </div>

      <div className="cases">
        {data.allSanityCases.edges.map(function(cases) {
          return (
            <div
              id={cases.node.caseCategory.id}
              className="case_wrapper animation--fade-up animation"
            >
              <Img
                fluid={cases.node.mainImage.image.asset.fluid}
                alt={cases.node.title}
              />

              <h2>{cases.node.title}</h2>
              <p>{cases.node.subTitle}</p>

              <Link className="blue_button" to={`/${cases.node.slug.current}/`}>
                Læs mere
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CaseContainer

function optionClicked() {
  let id = document.getElementById("caseCategories")
  let strUser = id.options[id.selectedIndex].value

  const allCases = document.querySelectorAll(".case_wrapper")

  if (strUser !== "all") {
    allCases.forEach(caseElement => {
      if (caseElement.id === strUser) {
        caseElement.style.display = "block"
      }
      if (caseElement.id !== strUser) {
        caseElement.style.display = "none"
      }
    })
  } else {
    allCases.forEach(caseElement => {
      caseElement.style.display = "block"
    })
  }
}

function tabClicked(e) {
  let strUser = e.target.id

  const allCases = document.querySelectorAll(".case_wrapper")
  const allTabs = document.querySelectorAll(".tab")

  allTabs.forEach(tab => {
    tab.classList.remove("tab_clicked")
  })

  e.target.classList.add("tab_clicked")

  if (strUser !== "all") {
    allCases.forEach(caseElement => {
      if (caseElement.id === strUser) {
        caseElement.style.display = "block"
      }
      if (caseElement.id !== strUser) {
        caseElement.style.display = "none"
      }
    })
  } else {
    allCases.forEach(caseElement => {
      caseElement.style.display = "block"
    })
  }
}
