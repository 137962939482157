import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CaseContainer from "../components/caseContainer"
import ContactForm from "../components/contactForm"
import PageOpening from "../components/pageOpening"

const Insights = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "daf45439-407c-5bb9-9181-e185388f94a5" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityCases(sort: { order: ASC, fields: publishedAt }) {
        edges {
          node {
            title
            _rawBody
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <div className="sub_header">
        <Link activeClassName="active" to="/cases/">
          Cases
        </Link>
        <Link activeClassName="active" to="/blog/">
          Blog
        </Link>
        <Link activeClassName="active" to="/nyheder/">
          Nyheder
        </Link>
      </div>
      <section className="index insights">
        <PageOpening data={data} />

        <BlockContent
          className={data.sanityPages.templateList}
          blocks={data.sanityPages._rawBody}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />

        <CaseContainer></CaseContainer>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}
export default Insights
